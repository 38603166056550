<template>
  <div class="bgBox" ref="imageWrapper">
    <div class="name">{{ info.name }}</div>
    <img class="bgImg" :src="img" alt="" />
    <div class="qrcode" ref="qrCodeUrl"></div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas'
import img from '@/assets/img/bg.jpg'
export default {
  data() {
    return {
      info: {},
      img,
      url:process.env.VUE_APP_BASE_API
    }
  },
  mounted() {
    this.info = this.$route.query
    this.creatQrCode()
    this.$nextTick(() => {
      this.toImage()
    })
  },
  methods: {
    // 生成二维码
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.url + 'register?code=' + this.info.code,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    // dom转图片
    toImage() {
      html2canvas(this.$refs.imageWrapper).then(canvas => {
        this.imgUrl = canvas.toDataURL('image/png')
        if (this.imgUrl !== '') {
          var a = document.createElement('a') // 创建一个a节点插入的document
          var event = new MouseEvent('click') // 模拟鼠标click点击事件
          a.download = '图片名字' // 设置a节点的download属性值
          a.href = this.imgUrl // 将图片的src赋值给a节点的href
          a.dispatchEvent(event) // 触发鼠标点击事件
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.bgBox {
  position: relative;
  width: 750px;
  height: 1334px;
  .bgImg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  .name {
    position: absolute;
    top: 64px;
    left: 330px;
    z-index: 2;
    font-size: 42px;
  }
  .qrcode {
    position: absolute;
    bottom: 44px;
    left: 40px;
    width: 160px;
    height: 160px;
  }
}
</style>
